import React, { useState, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faToggleOn, faToggleOff, faExpand, faPlay, faPause, faRedo } from '@fortawesome/free-solid-svg-icons';
import { faTextHeight, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './TeleprompterPage.css';
import { t } from 'i18next';

interface TeleprompterPageProps {
  teleprompterText: string;
  onExitClick: () => void;
}

const TeleprompterPage: React.FC<TeleprompterPageProps> = ({
  teleprompterText, onExitClick
}) => {
  const savedSpeed = Number(localStorage.getItem('speed')) || 100;
  const savedFontSize = Number(localStorage.getItem('fontSize')) || 80;

  const [mirror, setMirror] = useState<boolean>(false);
  const [fontSize, setFontSize] = useState<number>(savedFontSize);
  const [speed, setSpeed] = useState<number>(savedSpeed);
  const [scrollingText, setScrollingText] = useState<string>('');
  const [isScrolling, setIsScrolling] = useState<boolean>(false);
  const [maxTime, setMaxTime] = useState<number>(0);
  const [startTime, setStartTime] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<number>(0);
  const [pausedTime, setPausedTime] = useState<number>(0);

  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const timerIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const scrollIntervalRef = useRef<NodeJS.Timeout | null>(null);




  const startScrolling = () => {
    setIsScrolling(true);
    scrollText();
    if (scrollContainerRef.current) {
      const currentUnixTime = Math.floor(Date.now() / 1000);

      if (pausedTime > 0) {  // Means it's resuming after a pause
        setStartTime(currentUnixTime - pausedTime);
        setPausedTime(0);  // Reset paused time
      } else {  // This is a fresh start
        const scrollHeight = scrollContainerRef.current.scrollHeight;
        const animationDuration = Math.round((scrollHeight / speed) * 0.7);
        setMaxTime(animationDuration);
        setCurrentTime(0);
        setStartTime(currentUnixTime);  // Set fresh start time
      }
    }
  };

  const pauseScrolling = useCallback(() => {
    setIsScrolling(false);
    clearInterval(scrollIntervalRef.current!);
    const elapsedSeconds = Math.floor(Date.now() / 1000) - startTime;
    setPausedTime(elapsedSeconds);
  }, [startTime]);

  const resetScrolling = () => {
    pauseScrolling();
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0; // Reset to top
    }
  };


  const scrollText = () => {
    if (scrollContainerRef.current) {
      scrollIntervalRef.current = setInterval(() => {
        if (
          scrollContainerRef.current &&
          scrollContainerRef.current.scrollTop <
          scrollContainerRef.current.scrollHeight
        ) {
          scrollContainerRef.current.scrollTop += 1;
        } else {
          clearInterval(scrollIntervalRef.current!);
          setIsScrolling(false); // Stop scrolling when reaching the end
        }
      }, (1000 / speed) * 0.7); // Adjust scrolling speed
    }
  };

  const toggleFullscreen = () => {
    const root = document.documentElement;
    if (document.fullscreenElement) {
      document.exitFullscreen(); // Exit fullscreen
    } else {
      root.requestFullscreen().catch((error) => {
        console.error('Fullscreen request failed:', error);
      });
    }
  };

  useEffect(() => {
    setScrollingText(teleprompterText);
  }, [teleprompterText]);

  useEffect(() => {
    if (isScrolling) {
      const interval = 1000; // Update the timer every second
      timerIntervalRef.current = setInterval(() => {
        const elapsedSeconds = Math.floor(Date.now() / 1000) - startTime;
        const remainingTime = maxTime - elapsedSeconds;
        setCurrentTime(elapsedSeconds);
        if (remainingTime <= 0) {
          pauseScrolling();
        }
      }, interval);
    } else {
      clearInterval(timerIntervalRef.current!); // Ensure the timer stops when not scrolling
    }
    return () => {
      clearInterval(timerIntervalRef.current!);
    };
  }, [isScrolling, startTime, maxTime, pausedTime, pauseScrolling]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.style.fontSize = `${fontSize}px`;
    }
  }, [fontSize]);

  const formatTime = (timeInSeconds: number) => {
    const hours = Math.floor(timeInSeconds / 3600);
    const minutes = Math.floor((timeInSeconds - (hours * 3600)) / 60);
    const seconds = timeInSeconds - (hours * 3600) - (minutes * 60);

    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    return `${paddedHours}:${paddedMinutes}:${paddedSeconds}`;
  };

  return (
    <div className="teleprompter-page">
      <div className="teleprompter-toolbar">
        <button onClick={() => setMirror(!mirror)} title={t('mirror')}>
          {mirror ? <FontAwesomeIcon icon={faToggleOn} /> : <FontAwesomeIcon icon={faToggleOff} />}
        </button>
        <label title={t('fontSize')}>
          <FontAwesomeIcon icon={faTextHeight} />
          <input type="number" value={fontSize} onChange={(e) => {
            setFontSize(Number(e.target.value));
            localStorage.setItem('fontSize', e.target.value);
          }} />
        </label>


        <label title={t('speed')}>
          <FontAwesomeIcon icon={faTachometerAlt} />
          <input type="number" value={speed} onChange={(e) => {
            setSpeed(Number(e.target.value));
            localStorage.setItem('speed', e.target.value);
          }} />
        </label>

        {isScrolling ? (
          <button onClick={pauseScrolling} title={t('pause')}>
            <FontAwesomeIcon icon={faPause} />
          </button>
        ) : (
          <button onClick={startScrolling} title={t('start')}>
            <FontAwesomeIcon icon={faPlay} />
          </button>
        )}
        <button onClick={resetScrolling} title={t('reset')}>
          <FontAwesomeIcon icon={faRedo} />
        </button>
        <button onClick={toggleFullscreen} title={t('fullscreen')}>
          <FontAwesomeIcon icon={faExpand} />
        </button>
        <div className="teleprompter-timer">
          {formatTime(currentTime)}/{formatTime(maxTime)}
        </div>
        <button className="exit-button" title={t('exit')} onClick={onExitClick}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

      </div>


      <div className="arrow left-arrow">
        <svg width="120" height="120" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8 6L16 12L8 18" stroke="gray" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>

      <div className="arrow right-arrow">
        <svg width="120" height="120" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M16 6L8 12L16 18" stroke="gray" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </div>

      <div className="highlight-rectangle"></div>


      <div className={`teleprompter-text ${mirror ? 'mirrored' : ''}`} ref={scrollContainerRef}>
        <div className="top-padding"></div>  {/* Add this line */}
        <pre>
          {scrollingText}
        </pre>
        <div className="bottom-padding"></div>  {/* Add this line */}
      </div>

    </div>
  );
};

export default TeleprompterPage;
