import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// Import your translations
import enTranslations from './locales/en.json';
import azTranslations from './locales/az.json';
import trTranslations from './locales/tr.json';
import koTranslations from './locales/ko.json';
import ruTranslations from './locales/ru.json';

// This will allow TypeScript to know the shape of your translations
declare module 'react-i18next' {
  interface Resources {
    en: typeof enTranslations;
    az: typeof azTranslations;
    tr: typeof trTranslations;
    ko: typeof koTranslations;
    ru: typeof ruTranslations;
  }
}
const savedLanguage = localStorage.getItem('language') || 'en';
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: enTranslations },
      az: { translation: azTranslations },
      tr: { translation: trTranslations },
      ko: { translation: koTranslations },
      ru: { translation: ruTranslations },
    },
    lng: savedLanguage, // default language
    fallbackLng: 'en', // use English if the translation isn't available
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
