import React, { useState } from 'react';
import HomePage from './components/MainScreen';
import TeleprompterPage from './components/TeleprompterPage';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n'; // ensure the path points to your i18n.ts

function App() {
  const [currentPage, setCurrentPage] = useState<'home' | 'teleprompter'>('home');
  const [teleprompterText, setTeleprompterText] = useState<string>('');

  const handleStartClick = (text: string) => {
    setTeleprompterText(text);
    setCurrentPage('teleprompter');
  };

  const handleExitClick = () => {
    setCurrentPage('home');
  }

  return (
    <I18nextProvider i18n={i18n}>
      <div className="App">
        {currentPage === 'home' && <HomePage onStartClick={handleStartClick} />}
        {currentPage === 'teleprompter' && (
          <TeleprompterPage teleprompterText={teleprompterText} onExitClick={handleExitClick} />
        )}
      </div>
    </I18nextProvider>
  );
}

export default App;
