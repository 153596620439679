import React, { useState } from 'react';
import AceEditor from 'react-ace';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faPlay, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

import './MainScreen.css';
import 'ace-builds/src-noconflict/mode-plain_text'; // For plain text
import 'ace-builds/src-noconflict/theme-monokai';   // Theme you prefer
interface MainScreenProps {
  onStartClick: (text: string) => void;
}

const MainScreen: React.FC<MainScreenProps> = ({ onStartClick }) => {

  const { t, i18n } = useTranslation();
  const [text, setText] = useState<string>(localStorage.getItem('teleprompterText') || '');


  const handleInputChange = (newValue: string) => {
    setText(newValue);
    localStorage.setItem('teleprompterText', newValue);
  };

  const handlePromptClick = () => {
    onStartClick(text);
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  const handleClearClick = () => {
    setText('');  // clear the text state
    localStorage.setItem('teleprompterText', '');  // clear the local storage
  };

  return (
    <div className="main-screen">
      <div className="logo-container">
        <img src="logo.svg" alt="Teleprompter" className="logo" />
      </div>

      <div className="dropdown-wrapper">
        <select className="language-dropdown" value={i18n.language} title={t('language')} onChange={(e) => changeLanguage(e.target.value)}>
          <option value="en">{t('english')}</option>
          <option value="az">{t('azerbaijani')}</option>
          <option value="tr">{t('turkish')}</option>
          <option value="ko">{t('korean')}</option>
          <option value="ru">{t('russian')}</option>
        </select>
      </div>
      <h1 className="main-title"><img src="icon192.png" alt="Teleprompter" className="logo" /> {t('teleprompter')}</h1>

      <div className="instructions-columns">
        <ul className="column">
          <li><h2><FontAwesomeIcon icon={faPencilAlt} /></h2></li>
          <li>{t('instructionsInput')}</li>
          <li>{t('instructionsStart')}</li>
        </ul>
        <ul className="column">
          <li><h2><FontAwesomeIcon icon={faInfoCircle} /></h2></li>
          <li>{t('instructionsControls')}</li>
        </ul>
      </div>

      <AceEditor
        mode="plain_text"
        theme="monokai"
        value={text}
        onChange={handleInputChange}
        name="UNIQUE_ID_OF_DIV"
        editorProps={{ $blockScrolling: true }}
        fontSize={16}
        showPrintMargin={false}
        showGutter={true}
        highlightActiveLine={true}
        width="70%"
        height='300px'
        placeholder={t("textPlaceholder")}
        setOptions={{
          enableBasicAutocompletion: false,
          enableLiveAutocompletion: false,
          enableSnippets: false,
          showLineNumbers: true,
          tabSize: 2,
          wrap: true
        }}
      />

      <div className="button-group">
        <button onClick={handlePromptClick}><FontAwesomeIcon icon={faPlay} /> {t('prompt')}</button>
        <button onClick={handleClearClick}>
          <FontAwesomeIcon icon={faTrashAlt} /> {t('clear')}
        </button>
      </div>

      <footer className="main-footer">
        <p>{t('copyright', { 'year': new Date().getFullYear() })}</p>
      </footer>
    </div>
  );
};

export default MainScreen;
